import axios from "axios";
import router from "@/router";
import { message } from "@/plugins/element.js";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { showLoading, hideLoading } from "@/utils/loading.js";

// 创建axios实例
const request = axios.create({
  // api的base_url
  timeout: 600000, // 请求超时时间
  headers: {
    "content-type": "application/json;charset=UTF-8",
  },
});

// request.defaults.baseURL = 'https://applet.qiannianjituan.com'  // 测试环境/
request.defaults.baseURL = 'https://adminbayj.qiannianjituan.com'  // 正式环境
// request.defaults.baseURL = 'https://store.qiannianjituan.com/'

request.interceptors.request.use((response) => {
  NProgress.start();
  showLoading();
  let token = window.localStorage.getItem("admin_token") || "";
  // console.log(token);
  response.headers.AdminToken = token;
  return response;
});

request.interceptors.response.use(
  (response) => {
    hideLoading();
    NProgress.done();
    if (response.status != 200) {
      return message.error(response.data.data);
    }
    if (response.data.code == 10001 || response.data.code == 10002){
      window.localStorage.removeItem("admin_token");
      return router.push('/login')
    }
    return response;
  },
  (error) => {
    hideLoading();
    NProgress.done();
    message.error("发生未知错误， 请稍后重试！");
    if (error.message.includes("timeout")) {
      message.error("请求超时， 请检查网络后重试！");
    }
    return Promise.reject(error);
  }
);

export default request;
